import React, { useState } from "react"
import "./Header.css"
import { Pages } from "./pages"

const CloseIcon = () => (
  <svg
    className="menu-icon"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
)

const MenuIcon = () => (
  <svg
    className="menu-icon"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4 6h16M4 12h8m-8 6h16"
    />
  </svg>
)

function Header({ onShowMobileMenu = () => {} }) {
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  return (
    <nav className="header">
      <div className="header-items-container">
        <div className="header-item header-logo heading-font">
          <a href="/">Nikhil Mishra</a>
        </div>
        <div
          className="header-item"
          onClick={() => {
            onShowMobileMenu(!showMobileMenu)
            setShowMobileMenu(val => !val)
          }}
        >
          {showMobileMenu ? <CloseIcon /> : <MenuIcon />}
        </div>
        <ul className="header-item menu">
          {Pages.map(item => (
            <li key={item.url}>
              <a href={item.url}>{item.name}</a>
            </li>
          ))}
        </ul>
      </div>
      {showMobileMenu && (
        <ul className="menu-vertical">
          {Pages.map(item => (
            <li key={item.url}>
              <a href={item.url}>{item.name}</a>
            </li>
          ))}
        </ul>
      )}
    </nav>
  )
}

export default Header
