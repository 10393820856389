import React, { useState } from "react"
import "../../css/Typography.css"
import "../../css/Normalize.css"
import "../../css/DesignTokens.css"
import "./Layout.css"
import Header from "../header/Header"
import { Helmet } from "react-helmet"

function Layout({ children, title, heading }) {
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  return (
    <React.Fragment>
      <Helmet>
        <title> {title || "Home"} - Nikhil Mishra</title>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
      </Helmet>
      <Header onShowMobileMenu={show => setShowMobileMenu(show)} />
      <main
        className="content"
        style={{
          display: showMobileMenu ? "none" : "block",
        }}
      >
        {heading && <h1 className="page-heading">{heading}</h1>}
        {children}
      </main>
      <footer className="footer">
        <div className="footer-content">
          <div>© Nikhil Mishra {new Date().getFullYear()}</div>
          <div>
            Crafted by{" "}
            <a
              href="https://swapnilmishra.github.io/notes/about"
              target="_blank"
              rel="noopener noreferrer"
            >
              Swapnil Mishra
            </a>
          </div>
        </div>
      </footer>
    </React.Fragment>
  )
}

export default Layout
