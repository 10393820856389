export const PageCommerical = { name: "Wedding", url: "/wedding" }
export const PageDocumentary = { name: "Photo Stories", url: "/photo-stories" }
export const PageTravel = {
  name: "Travel and Street",
  url: "/travel-and-street",
}
export const PagePhotobook = { name: "Handmade photobook", url: "/photobook" }
export const PageAbout = { name: "About", url: "/about" }

export const Pages = [
  PageDocumentary,
  PageCommerical,
  PageTravel,
  // PagePhotobook,
  PageAbout,
]
